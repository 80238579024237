import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './Component.js';

const root = ReactDOM.createRoot(document.getElementById('load'));

function App()
{
    return(
        <>
                <BrowserRouter>
                    <Routes>
                        <Route exact path='/' element={<Home />} />
                    </Routes>
                </BrowserRouter>  
        </>
    )
}

root.render(<App />);